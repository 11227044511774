import Button from "react-bootstrap/Button";
import styles from "./DiscountInfoBox.module.scss";
import { calcDiscountFromCouponOnPrice } from "../../utils/discounts";
import { ProductPriceListing } from "../../types/product";
import { DiscountCode } from "../../types/discount";

interface DiscountInfoBoxPropsInterface {
  applied: boolean;
  price: ProductPriceListing | null;
  discount: DiscountCode;
  className?: string;
}

export const DiscountInfoBox = (props: DiscountInfoBoxPropsInterface) => {
  return (
    <div
      className={`${styles.container} d-flex fs-7 justify-content-between ${
        props.className ? props.className : ""
      }`}
    >
      <div className="d-flex align-items-center">
        <span className={`${styles.discountAmountContainer} fw-semibold`}>
          <span className={styles.amount}>
            $
            {calcDiscountFromCouponOnPrice(props.price, props.discount).toFixed(
              2
            )}
          </span>
          <span>off</span>
        </span>
        <span>{props.discount.codeName}</span>
      </div>
      {props.applied ? (
        <span className={`${styles.appliedLabel}`}>Applied</span>
      ) : (
        <Button>Apply</Button>
      )}
    </div>
  );
};
