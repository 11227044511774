import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { ProductInterface, ProductPriceListing } from "../types/product";
import { intervalAtom } from "./interval";
import { priceGroupUrlQueryValueAtom } from "./price_group";
import { parseProductApiResponseItem } from "../utils/product";
import { selectedCurrencyAtom } from "./country";
import { Interval } from "../types/interval";

const productUrlIdentifierAtom = atom("");

export const setProductUrlIdentifierAtom = atom(
  null,
  (_get, set, paramValue: string) => {
    set(productUrlIdentifierAtom, paramValue);
  }
);

const asyncRetrieveProductAtom = atom<Promise<ProductInterface>>(
  async (get) => {
    const productUrl = get(productUrlIdentifierAtom);
    if (productUrl) {
      const priceGroupName = get(priceGroupUrlQueryValueAtom);
      const queryParams = priceGroupName
        ? `?price_group=${priceGroupName}`
        : "";
      const response = await fetch(
        `${process.env.REACT_APP_API}/products/get-by-checkout-url/${productUrl}/${queryParams}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();

      // Even when the endpoint could return a collection
      // this UI expect only 1 product match
      // TODO: Remote alert in case of more than one product is retrieve by the request
      if (res.data.length >= 1) {
        return parseProductApiResponseItem(res.data[0]);
      }
    }
    return new Promise(() => null);
  }
);

export const loadableRetrieveProductsAtom = loadable(asyncRetrieveProductAtom);

export const productAtom = atom<ProductInterface | null>((get) => {
  const loadableProduct = get(loadableRetrieveProductsAtom);
  if (loadableProduct.state === "hasError") return null;
  if (loadableProduct.state === "loading") return null;
  return loadableProduct.data;
});

export const selectedPriceAtom = atom<ProductPriceListing | null>((get) => {
  const selectedCurrency = get(selectedCurrencyAtom);
  if (!selectedCurrency) {
    return null;
  }
  const loadableProduct = get(loadableRetrieveProductsAtom);
  const interval = get(intervalAtom);
  if (loadableProduct.state === "hasError") return null;
  if (loadableProduct.state === "loading") return null;
  const priceOfInterest = loadableProduct.data.prices.find((price) => {
    return (
      price.interval === interval && price.currencyId === selectedCurrency.id
    );
  });
  return priceOfInterest ? priceOfInterest : null;
});

export const counterpartPriceAtom = atom<ProductPriceListing | null>((get) => {
  const selectedCurrency = get(selectedCurrencyAtom);
  if (!selectedCurrency) {
    return null;
  }
  const loadableProduct = get(loadableRetrieveProductsAtom);
  const interval = get(intervalAtom);
  let counterInterval = interval;

  if (interval === Interval.MONTHLY) {
    counterInterval = Interval.ANNUAL;
  }

  if (interval === Interval.ANNUAL) {
    counterInterval = Interval.MONTHLY;
  }

  if (loadableProduct.state === "hasError") return null;
  if (loadableProduct.state === "loading") return null;
  const priceOfInterest = loadableProduct.data.prices.find((price) => {
    return (
      price.interval === counterInterval &&
      price.currencyId === selectedCurrency.id
    );
  });
  return priceOfInterest ? priceOfInterest : null;
});

export const savingOnChangePriceAtom = atom((get) => {
  const selectedPrice = get(selectedPriceAtom);
  const counterpartPrice = get(counterpartPriceAtom);
  if (selectedPrice && counterpartPrice) {
    return selectedPrice.annualPrice - counterpartPrice.annualPrice;
  }
  return NaN;
});

export const savingPercentageOnChangePriceAtom = atom((get) => {
  const selectedPrice = get(selectedPriceAtom);
  const counterpartPrice = get(counterpartPriceAtom);
  if (selectedPrice && counterpartPrice) {
    const difference = selectedPrice.annualPrice - counterpartPrice.annualPrice;
    return difference / counterpartPrice.annualPrice;
  }
  return NaN;
});
