import { useEffect, useState } from "react";
import { AchConnectionButton } from "./ach-connection-button/AchConnectionButton";
import { ReactComponent as Ribbon } from "../../assets/ribbon-icon.svg";
import styles from "./AchConnection.module.scss";
import { AchBankAccountInformation } from "./ach-bank-account-information/AchBankAccountInformation";
import { useAtom } from "jotai";
import { setPaymentInformationAchAtom } from "../../lib/payment-information";

export interface achAccountConnectionPayload {
  public_token: string;
  account_id: string;
  accountMetadata: {
    account: {
      id: string;
      name: string;
      mask: string;
      type: string;
      subtype: string;
    };
    institution: {
      institution_id: string;
      name: string;
    };
  };
}

const connectAch = async (setLinkToken: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/registrations/ach/keys/`,
    {
      method: "GET",
    }
  );
  const data = await response.json();
  setLinkToken(data.plaid_link_token);
};

const getAccountApproval = async (
  accountId: string,
  publicToken: string
): Promise<{
  isAccountOk: boolean;
  message: string;
}> => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/registrations/ach/check-balance/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        account_id: accountId,
        public_token: publicToken,
      }),
    }
  );
  const data = await response.json();
  if (response.ok) {
    return {
      isAccountOk: true,
      message: "",
    };
  } else {
    return {
      isAccountOk: false,
      message: data.message,
    };
  }
};

export const AchConnection = () => {
  const [linkToken, setLinkToken] = useState(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [publicToken, setPublicToken] = useState<string | null>(null);
  const [accountMetadata, setAccountMetadata] = useState<
    achAccountConnectionPayload["accountMetadata"] | null
  >(null);
  const [isAccountOk, setIsAccountOk] = useState(false);
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const [, setPaymentInformation] = useAtom(setPaymentInformationAchAtom);

  useEffect(() => {
    connectAch(setLinkToken).then((r) => null);
  }, []);

  useEffect(() => {
    if (accountMetadata && publicToken) {
      getAccountApproval(accountMetadata.account.id, publicToken).then(
        ({ isAccountOk, message }) => {
          setIsAccountOk(isAccountOk);
          setAccountErrorMessage(message);
        }
      );
    }
  }, [accountMetadata, publicToken]);

  useEffect(() => {
    if (isAccountOk) {
      if (clientId && publicToken && accountMetadata) {
        setPaymentInformation({
          plaidClientId: clientId,
          bankAccountId: accountMetadata.account.id,
          plaidPublicToken: publicToken,
        });
      }
    } else {
      setPaymentInformation({
        plaidClientId: "",
        bankAccountId: "",
        plaidPublicToken: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountOk, clientId, publicToken, accountMetadata]);

  return (
    <>
      <div
        className={`d-flex align-items-center mb-4 ${styles.bannerContainer}`}
      >
        <Ribbon className={styles.ribbon} />
        <p
          className={`mb-0 fs-7 ${styles.bannerCaption}`}
          style={{ marginLeft: "1em" }}
        >
          By registering a bank account, you will receive $20 in deductible
          credit toward future claims!
        </p>
      </div>
      {clientId && publicToken && accountMetadata ? (
        <AchBankAccountInformation
          message={accountErrorMessage}
          accountName={accountMetadata.account.name}
          institution={accountMetadata.institution.name}
          accountType={accountMetadata.account.type}
          linkToken={linkToken}
          accountMask={accountMetadata.account.mask}
          onUpdateAchPaymentData={(data) => {
            if (data.account_id && data.public_token && data.accountMetadata) {
              setClientId(data.account_id);
              setPublicToken(data.public_token);
              setAccountMetadata(data.accountMetadata);
            }
          }}
        />
      ) : (
        <AchConnectionButton
          linkToken={linkToken}
          onUpdateAchPaymentData={(data) => {
            if (data.account_id && data.public_token && data.accountMetadata) {
              setClientId(data.account_id);
              setPublicToken(data.public_token);
              setAccountMetadata(data.accountMetadata);
            }
          }}
        />
      )}
    </>
  );
};
